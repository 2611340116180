const API_CUSTOMER_SIGNUP = "/api/customer/signup";
const API_CUSTOMER_FORGOT = "/api/customer/forgotpassword";
const API_CUSTOMER_LOGIN = "/api/customer/login";
const API_CUSTOMER_LOGOUT = "/api/customer/logout";
const API_CUSTOMER_RESET = "/api/customer/resetpassword";
const API_CUSTOMER_CHANGEPASS = "/api/customer/changepassword";
const API_CUSTOMER_UPDATEPROFILE = "/api/customer/updateprofile";

const API_QUICK_STATS = "/api/home/quickstats";
const API_SHOPPING_LIST = "/api/shopping/list";
const API_SHOPPING_ADD = "/api/shopping/add";
const API_SHOPPING_DELETE = "/api/shopping/delete";
const API_SHOPPING_UPDATE = "/api/shopping/update";
const API_RECEIPT_LIST = "/api/receipt/list";
const API_RECEIPT_ADD = "/api/receipt/add";
const API_RECEIPT_DELETE = "/api/receipt/delete";
const API_RECEIPT_ITEM_DELETE = "/api/receipt/item/delete";
const API_RECEIPT_ITEM_ADD = "/api/receipt/item/add";
const API_RECEIPT_ITEM_UPDATE = "/api/receipt/item/update";
const API_RECEIPT_ITEM_LIST = "/api/receipt/item/list";

const API_GENERAL_UPLOAD = "/api/general/upload";
const API_ADDRESS_ADD = "/api/address/add";
const API_COUPONS_LIST = "/api/coupons/list";

const API_NOTIFICATION_LIST = "/api/notifications/list";

const endpoints = {
  API_CUSTOMER_SIGNUP,
  API_CUSTOMER_FORGOT,
  API_CUSTOMER_LOGIN,
  API_CUSTOMER_LOGOUT,
  API_CUSTOMER_RESET,
  API_QUICK_STATS,
  API_SHOPPING_LIST,
  API_SHOPPING_ADD,
  API_SHOPPING_DELETE,
  API_SHOPPING_UPDATE,
  API_RECEIPT_LIST,
  API_GENERAL_UPLOAD,
  API_RECEIPT_ADD,
  API_ADDRESS_ADD,
  API_COUPONS_LIST,
  API_CUSTOMER_CHANGEPASS,
  API_CUSTOMER_UPDATEPROFILE,
  API_RECEIPT_DELETE,
  API_RECEIPT_ITEM_LIST,
  API_RECEIPT_ITEM_DELETE,
  API_RECEIPT_ITEM_ADD,
  API_RECEIPT_ITEM_UPDATE,
  API_NOTIFICATION_LIST,
};

export default endpoints;
