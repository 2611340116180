import React, { useEffect, useState } from "react";
import { UserInfoContext } from ".";
const UserInfoProvider = ({ children }) => {
  const [userInfo, updateUserInfo] = useState(false);

  const setUserInfo = (info) => {
    updateUserInfo(info);
    localStorage.setItem("userInfo", JSON.stringify(info));
  };
  useEffect(() => {
    const getUserInfo = localStorage.getItem("userInfo");
    updateUserInfo(JSON.parse(getUserInfo));
  }, []);

  return (
    <>
      <UserInfoContext.Provider
        value={{
          setUserInfo,
          userInfo,
        }}
      >
        {children}
      </UserInfoContext.Provider>
    </>
  );
};

export default UserInfoProvider;
