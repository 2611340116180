import "./Scss/custom.scss";
import Router from "./Router/Router";
import LoginProvider from "./Context/login.context";
import UserInfoProvider from "./Context/userInfo.context";
import ErrorBoundary from "./Component/errorBoundary";
function App() {
  return (
    <ErrorBoundary>
      <LoginProvider>
        <UserInfoProvider>
          <Router></Router>
        </UserInfoProvider>
      </LoginProvider>
    </ErrorBoundary>
  );
}

export default App;
