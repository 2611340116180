import React, { Component } from "react";
import { Col, Row, Typography } from "antd";
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Row className="template__guest" align="stretch" justify="center">
            <Col className="template__guest--left py-2" span={24}>
              <Row className="mh-100" align="middle" justify="center">
                <Col
                  className="template__guest--left_inner text-center"
                  span={24}
                >
                  <Typography.Title>Oops!</Typography.Title>
                  <Typography.Title level={4}>
                    {this.state?.error?.code}
                  </Typography.Title>
                  <Typography.Title level={5}>
                    Sorry, an unexpected error has occurred.
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      );
    }

    return this.props.children;
  }
}
