import http from "./http.service";
import { notification } from "antd";
// let apiUrl = process.env.REACT_APP_API_URL

let apiUrl = "https://app-apis.foodshadowservicetoothers.com";

const successCodes = [200, 100, 404];

const postCall = async (endpoint, input, showMessage = true) => {
  try {
    const { data } = await http.post(apiUrl + endpoint, input);

    const { message, success } = data;
    if (success && showMessage)
      notification.success({
        message: "Success",
        description: message,
      });

    if (successCodes.includes(data.statusCode)) return data;
    else {
      notification.error({
        message: "Error",
        description: data?.message,
      });
      return false;
    }
  } catch (error) {
    return false;
  }
};

const uploadCall = async (endpoint, input, showMessage = false) => {
  try {
    const { data } = await http.post(apiUrl + endpoint, input, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { message, success } = data;
    if (success && showMessage)
      notification.success({
        message: "Success",
        description: message,
      });
    if (successCodes.includes(data.statusCode)) return data;
    else {
      notification.error({
        message: "Error",
        description: data?.message,
      });
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getCall = async (endpoint, params) => {
  try {
    const ulrParams = params && new URLSearchParams(params).toString();
    const { data } = await http.get(
      `${apiUrl}${endpoint}${"?" + ulrParams || ""}`
    );
    if (successCodes.includes(data.statusCode)) return data;
    else {
      notification.error({
        message: "Error",
        description: data?.message,
      });
      return false;
    }
  } catch (error) {
    return false;
  }
};

const putCall = async (endpoint, input, showMessage = true) => {
  try {
    const { data } = await http.put(apiUrl + endpoint, input);
    const { message, success } = data;
    if (success && showMessage)
      notification.success({
        message: "Success",
        description: message,
      });

    if (successCodes.includes(data.statusCode)) return data;
    else {
      notification.error({
        message: "Error",
        description: data?.message,
      });
      return false;
    }
  } catch (error) {
    return false;
  }
};

const deleteCall = async (endpoint, params, showMessage = true) => {
  try {
    const ulrParams = params && new URLSearchParams(params).toString();
    const { data } = await http.delete(
      `${apiUrl}${endpoint}${"?" + ulrParams || ""}`
    );
    const { message, success } = data;
    if (success && showMessage)
      notification.success({
        message: "Success",
        description: message,
      });

    if (successCodes.includes(data.statusCode)) return data;
    else {
      notification.error({
        message: "Error",
        description: data?.message,
      });
      return false;
    }
  } catch (error) {
    return false;
  }
};
export { postCall, getCall, putCall, deleteCall, uploadCall };
