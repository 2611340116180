import { Typography } from "antd";
import closeIcon from "../Assests/cross-icon.svg";
import { CheckOutlined, CloseSquareOutlined } from "@ant-design/icons";

import iconSuccess from "../Assests/success-tick.svg";

const { Title, Text } = Typography;
const successConfig = (title, message, imgsrc, hasError) => {
  return {
    okButtonProps: { sizw: "large", block: true, type: "primary" },
    cancelButtonProps: { sizw: "large", block: true, type: "danger" },
    centered: true,
    closable: true,
    closeIcon: <img src={closeIcon} alt="closeicon" />,
    icon: (
      <div className="modal-icon">
        {hasError ? (
          <CloseSquareOutlined style={{ fontSize: "46px", color: "#FF4B2D" }} />
        ) : imgsrc ? (
          <img src={imgsrc} alt="modal icon" />
        ) : (
          <CheckOutlined style={{ fontSize: "26px", color: "#FF4B2D" }} />
        )}
      </div>
    ),
    title: (
      <Title level={3} block="true">
        {title}
      </Title>
    ),

    content: (
      <>
        <Text type="secondary" className="px-2 ant-modal-confirm-content">
          {message}
        </Text>
      </>
    ),
  };
};

const confirmConfig = (title, message, Icon) => {
  return {
    okButtonProps: {
      sizw: "large",
      type: "primary",
      danger: true,
      block: "true",
    },
    cancelButtonProps: { sizw: "large", type: "primary", block: "true" },
    centered: true,
    closable: true,
    closeIcon: <img src={closeIcon} alt="closeicon" />,
    icon: <div className="modal-icon">{Icon}</div>,
    title: (
      <Title level={3} block="true">
        {title}
      </Title>
    ),
    content: (
      <>
        <Text type="secondary" className="px-2 ant-modal-confirm-content">
          {message}
        </Text>
      </>
    ),
  };
};

const sMessage = (title, message) => {
  return {
    okButtonProps: {
      sizw: "large",
      type: "primary",
      block: "true",
    },
    cancelButtonProps: { sizw: "large", type: "primary", block: "true" },
    centered: true,
    closable: true,
    closeIcon: <img src={closeIcon} alt="closeicon" />,
    icon: (
      <div className="modal-icon">
        <img src={iconSuccess} alt="modal icon" />
      </div>
    ),
    title: (
      <Title level={3} block="true">
        {title}
      </Title>
    ),
    content: (
      <>
        <Text type="secondary" className="px-2 ant-modal-confirm-content">
          {message}
        </Text>
      </>
    ),
  };
};

export default { successConfig, confirmConfig, sMessage };
