import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoginContext } from ".";
const LoginProvider = (props) => {
  const [isSignedIn, updateSignIn] = useState(true);
  const [token, setToken] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const setLoginToken = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };
  const logout = () => {
    localStorage.clear("token");
    setToken(null);
  };
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  return (
    <>
      <LoginContext.Provider
        value={{
          isSignedIn,
          updateSignIn,
          token,
          setLoginToken,
          setLoading,
          logout,
        }}
      >
        <Spin spinning={isLoading} size="large">
          <div className="food-shadow-app-container">{props.children}</div>
        </Spin>
      </LoginContext.Provider>
    </>
  );
};

export default LoginProvider;

// export default LoginContext;
