import axios from "axios";
import { notification } from "antd";

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.headers["Authorization"] = `${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => {
    /*Error Handling*/
    return Promise.reject(error);
  }
);

/*Intercepting the response in case of specif status codes*/
axios.interceptors.response.use(
  function (success) {
    // console.log(
    //   "*****************success from interceptor**************",
    //   success
    // );
    return Promise.resolve(success);
  },
  function (error) {
    // console.log("*****************error from interceptor**************", error);

    if (
      error &&
      error.response &&
      error.response.data.code === 3006 &&
      error.response.data.errors.length > 0
    ) {
      let errors = error.response.data.errors;
      let t = errors.map((e, i) => <p key={i}>{e}</p>);

      notification.error({
        message: "Error",
        description: t,
      });
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.code !== 3006
    ) {
      notification.error({
        message: "Error",
        description:
          error.response.data.message ||
          "Currently experiencing some trouble accessing the back-end services. Please try again in a short while.",
      });
    }

    /*if all above process failes breaking the queue*/
    return Promise.reject(error);
  }
);

let methods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default methods;
