import React, { useContext } from "react";
import { Menu, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from "../../Context";

import { ReactComponent as CartIcon } from "../../Assests/cart.svg";
import { ReactComponent as LocationIcon } from "../../Assests/location.svg";
import { ReactComponent as LogoutIcon } from "../../Assests/logout-icon.svg";
import { ReactComponent as SettingsIcon } from "../../Assests/settings.svg";
import { ReactComponent as DashboardIcon } from "../../Assests/dashboard.svg";
import { ReactComponent as ReceiptIcon } from "../../Assests/receipt-unselected.svg";
import { modalConfig } from "../../Lib";
import { InfoCircleOutlined } from "@ant-design/icons";

const SideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout } = useContext(LoginContext);

  const items = [
    {
      label: "Dashboard",
      key: "/dashboard",
      icon: <DashboardIcon width={24} fill="#657081" />,
    },
    {
      label: "Locations",
      key: "/locations",
      icon: <LocationIcon width={24} fill="#657081" />,
    },
    {
      label: "Shopping List",
      key: "/shopping-list",
      icon: <CartIcon width={24} fill="#657081" />,
    },
    {
      label: "Receipt",
      key: "/receipt",
      icon: <ReceiptIcon width={24} fill="#657081" />,
    },
    {
      label: "Settings",
      key: "/settings",
      icon: (
        <SettingsIcon
          className="no_fill strok_on_active"
          width={24}
          stroke="#657081"
        />
      ),
    },
    {
      label: "logout",
      key: "logout",
      icon: <LogoutIcon width={24} className="no_fill" />,
    },
  ];

  const onClick = (e) => {
    if (e.key === "logout") {
      Modal.confirm({
        ...modalConfig.confirmConfig(
          "Logout",
          <>Are you sure you want to logout?</>,
          <InfoCircleOutlined style={{ fontSize: 42, color: "#FF4B2D" }} />
        ),
        onOk: () => {
          logout();
        },
        confirmLoading: true,
        okText: "Confirm",
        cancelText: "Cancel",
      });
    } else navigate(e.key);
  };
  return (
    <>
      <Menu
        selectedKeys={[pathname]}
        className="template__login--sideMenu"
        onClick={onClick}
        style={
          {
            // width: 250,
          }
        }
        mode="vertical"
        items={items}
      />
    </>
  );
};

export default SideMenu;
