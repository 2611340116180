import React, { lazy, Suspense } from "react";
import Protected from "./ProtectedRoute";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Row, Spin } from "antd";

const ReceiptDetails = lazy(() => import("../Pages/Receipt/itemDetails"));
const Login = lazy(() => import("../Pages/Accounts/login"));
const Forgot = lazy(() => import("../Pages/Accounts/forgotPassword"));
const Guest = lazy(() => import("../Component/Templates/guest"));
const Reset = lazy(() => import("../Pages/Accounts/resetPassword"));
const NomatchFound = lazy(() => import("../Pages/NomatchFound"));
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const ShoppingList = lazy(() => import("../Pages/ShoppingList"));
const Create = lazy(() => import("../Pages/Accounts/createAccount"));
const CouponsPage = lazy(() => import("../Pages/Coupons"));
const LocationMap = lazy(() => import("../Pages/Location"));
const Settings = lazy(() => import("../Pages/Settings"));
const ChangePassword = lazy(() => import("../Pages/Settings/changePassword"));
const UpdateProfile = lazy(() => import("../Pages/Settings/Update"));
const Receipt = lazy(() => import("../Pages/Receipt"));

const Router = () => {
  return (
    <>
      <Suspense
        fallback={
          <Row style={{ height: "100vh" }} align="middle" justify={"center"}>
            <Spin spinning={true} size="large"></Spin>
          </Row>
        }
      >
        <HashRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Guest>
                  <Login />
                </Guest>
              }
            />
            <Route
              exact
              path="/forgot"
              element={
                <Guest>
                  <Forgot />
                </Guest>
              }
            />
            <Route
              exact
              path="/reset"
              element={
                <Guest>
                  <Reset />
                </Guest>
              }
            />
            <Route
              exact
              path="/create"
              element={
                <Guest>
                  <Create />
                </Guest>
              }
            />
            <Route
              exact
              path="/dashboard"
              element={
                <Protected heading="Dashboard">
                  <Dashboard />
                </Protected>
              }
            />
            <Route
              exact
              path="/locations"
              element={
                <Protected heading="Locations">
                  <LocationMap></LocationMap>
                </Protected>
              }
            />
            <Route
              exact
              path="/shopping-list"
              element={
                <Protected heading="Shopping List">
                  <ShoppingList></ShoppingList>
                </Protected>
              }
            />
            <Route
              exact
              path="/coupons"
              element={
                <Protected heading="Coupons">
                  <CouponsPage></CouponsPage>
                </Protected>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <Protected heading="Settings">
                  <Settings></Settings>
                </Protected>
              }
            ></Route>
            <Route
              path="/change-password"
              element={
                <>
                  <Protected heading="Update password">
                    <ChangePassword />
                  </Protected>
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <Protected heading="Profile">
                    <>
                      <UpdateProfile></UpdateProfile>
                    </>
                  </Protected>
                </>
              }
            />

            <Route
              exact
              path="/receipt"
              element={
                <Protected heading="Receipt">
                  <Receipt />
                </Protected>
              }
            ></Route>
            <Route
              exact
              path="/receipt/:id"
              element={
                <Protected heading="Receipt">
                  <ReceiptDetails />
                </Protected>
              }
            />
            <Route path="*" element={<NomatchFound></NomatchFound>} />
          </Routes>
        </HashRouter>
      </Suspense>
    </>
  );
};

export default Router;
