import React from "react";
import { Col, List, Row, Typography } from "antd";

import passwordChanged from "../../Assests/passwordChanged.svg";
import itemExpiring from "../../Assests/itemExpiring.svg";
import profileUpdated from "../../Assests/profileUpdated.svg";
import itemDonate from "../../Assests/donate-now-illustration-icon.svg";
const { Text, Title } = Typography;
const NotificationList = ({ list, paginateItems }) => {
  const getImageSrc = (key) => {
    switch (key) {
      case "profileUpdated":
        return profileUpdated;
      case "itemExpiring":
        return itemExpiring;
      case "passwordChanged":
        return passwordChanged;
      case "itemDonate":
        return itemDonate;
      default:
        return profileUpdated;
    }
  };

  return (
    <>
      <List
        size="large"
        pagination={{
          onChange: (page, size) => {
            paginateItems(page, size);
          },
          total: list?.count,
          hideOnSinglePage: true,
        }}
        itemLayout="horizontal"
        dataSource={list?.rows}
        renderItem={(item) => (
          <List.Item className="bg-white" style={{ padding: 5 }}>
            <Row align={"middle"} justify="space-between" className="w-100 ">
              <Col span={24}>
                <Row align={"middle"} wrap={true} justify="start">
                  <Col>
                    <img
                      className="mr-2"
                      width={32}
                      src={getImageSrc(item.type)}
                      alt={item.title}
                    ></img>
                  </Col>
                  <Col>
                    <Title className="m-0 px-2" level={5}>
                      {item.title}
                    </Title>
                    <Text className="m-0 px-2" type="secondary">
                      {item?.body}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default NotificationList;
