import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "../Context";
import LoginTemplate from "../Component/Templates/loggedIn";
function Protected(props) {
  const { token } = useContext(LoginContext);
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return <LoginTemplate {...props}></LoginTemplate>;
}
export default Protected;
