import React, { useRef, useContext, useState, useEffect } from "react";
import { Col, Layout, Typography, Row, Popover, Button, Avatar } from "antd";
import SideMenu from "./sideMenu";
import bell from "../../Assests/bell-icon-with-red-dot.svg";
import { UserInfoContext } from "../../Context";
import { endpoints, IMAGE_BASE_URL } from "../../Lib";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getCall } from "../../Services/apiCall.service";
import NotificationList from "./notificationsList";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const LoginTemplate = ({ children, heading }) => {
  const { userInfo } = useContext(UserInfoContext);
  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getNotifications(1, 10);
  }, []);

  const getNotifications = async (page, limit) => {
    const payload = {
      page: page - 1,
      limit,
      customerId: userInfo.id,
    };

    const { data, success } = await getCall(
      endpoints.API_NOTIFICATION_LIST,
      payload
    );
    if (success) {
      setNotifications(data || []);
    } else {
      setNotifications([]);
    }
  };

  const handleOpenChange = (open) => {
    if (open) {
      getNotifications(1, 10);
    }
  };

  return (
    <>
      <Layout className="template__login">
        <Sider theme="light" className="template__login--sider" width={"auto"}>
          <div style={{ height: 100 }} className="template__login--logo" />
          <SideMenu></SideMenu>
        </Sider>
        <Layout>
          <Header
            style={{ height: 100, background: "#fff" }}
            className="template__login--header"
          >
            <Row align={"middle"} justify="space-between">
              <Col>
                <Title level={3} block="true">
                  {heading}
                </Title>
              </Col>
              <Col>
                <Row align={"middle"}>
                  <Col className="col-in-header">
                    <Popover
                      destroyTooltipOnHide
                      onOpenChange={handleOpenChange}
                      placement="bottomRight"
                      className="mx-2"
                      content={
                        <>
                          <NotificationList
                            paginateItems={(page, size) =>
                              getNotifications(page, size)
                            }
                            list={notifications}
                          ></NotificationList>
                        </>
                      }
                      trigger="click"
                    >
                      <Button type="ghost" className="in-header-button">
                        <img height={18} src={bell} alt="notification icon" />
                      </Button>
                    </Popover>
                  </Col>
                  <Col className="col-in-header">
                    <Button
                      onClick={() => navigate("/settings")}
                      type="ghost"
                      className="in-header-button"
                    >
                      <Avatar
                        className={`${
                          userInfo?.image &&
                          "user-profile-edit-avatar-in-header"
                        }`}
                        style={{
                          background: userInfo?.image && "transparent",
                        }}
                        size={40}
                        icon={
                          userInfo?.image ? (
                            <>
                              <div
                                style={{
                                  backgroundImage: `url(${IMAGE_BASE_URL}${userInfo?.image})`,
                                }}
                                className="user-profile-edit-image in-header"
                              ></div>
                            </>
                          ) : (
                            <UserOutlined />
                          )
                        }
                      />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content className="template__login--content">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

export default LoginTemplate;
